import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import FeatureHeader from "../components/sections/feature-header"
import FeatureLinks from "../components/sections/feature-links"
import Footer from "../components/sections/footer"

const headline = "Ratgeber & Experten-Tipps"
const text =
  "Der WelpenOrganizer bietet Ihnen wertvolle Ratschläge sowie Tipps von Experten und Kunden. Er hilft Ihnen mit entscheidenden Informationen die richtige Wahl zu treffen, um Zeit, Geld und Nerven zu sparen."

const Ratgeber = () => {
  return (
    <Layout>
      <SEO title={headline} />
      <Navigation />
      <FeatureHeader page={"ratgeber"} headline={headline} text={text} />
      <FeatureLinks />
      <Footer />
    </Layout>
  )
}

export default Ratgeber
